import React, { Component } from "react";
import parseApiResults from "../../utils/reps";
import { Search, MyLocation, ArrowDropDown } from "@material-ui/icons";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import "./styles.scss";

const axios = require("axios");
const API_KEY = "AIzaSyA5Mfa8ul--mt9BVK9-hD1qWalB_omD9R4";
const API_ADDRESS = "https://www.googleapis.com/civicinfo/v2/representatives";

class LocationSearchBar extends Component {
  constructor(props) {
    super(props);
  }

  populateSearchAndSubmit(latitude, longitude) {
    const address = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${API_KEY}`;

    axios
      .get(address)
      .then((response) => {
        const address = response.data.results[0].formatted_address;
        this.props.updateAddress({ currentTarget: { value: address } });
        this.hitCivicApi(address);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  geolocate = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      this.populateSearchAndSubmit(
        position.coords.latitude,
        position.coords.longitude
      );
    });
  };

  hitCivicApi = (address = null) => {
    const civicAddress = address || this.props.address;
    axios
      .get(`${API_ADDRESS}?key=${API_KEY}&address=${civicAddress}`)
      .then((response) => {
        const apiResults = parseApiResults(response.data);
        const { city, state } = response.data.normalizedInput;
        this.props.handleApiSearch({
          ...apiResults,
          activeCity: city,
          activeState: state,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    this.hitCivicApi();
  };

  render() {
    return (
      <div className="form-container">
        <form className="searchForm" onSubmit={this.handleFormSubmit}>
          <div className="searchBarContainer">
            <input
              type="text"
              className="searchbar"
              value={this.props.address}
              onChange={this.props.updateAddress}
              placeholder="Enter Address / City / State / Zip code to find your representatives"
            />
          </div>
          <div className="buttonContainer">
            <button type="submit" className="searchButton">
              <Search className="icon" />
            </button>
            <button
              className="geolocate"
              type={"button"}
              onClick={this.geolocate}
            >
              <MyLocation className="icon" />
            </button>
          </div>
          <div className="checkboxContainer">
            <div className="labels">
              <label className="checkboxDiv">
                <input
                  type="checkbox"
                  onChange={this.props.updateCheckboxes}
                  name="federal"
                  checked={this.props.checkboxes.federal}
                />
                Federal
              </label>
              <label className="checkboxDiv">
                <input
                  type="checkbox"
                  onChange={this.props.updateCheckboxes}
                  name="state"
                  checked={this.props.checkboxes.state}
                />
                State
              </label>
              <label className="checkboxDiv">
                <input
                  type="checkbox"
                  onChange={this.props.updateCheckboxes}
                  name="local"
                  checked={this.props.checkboxes.local}
                />
                Local
              </label>
              <label className="checkboxDiv">
                <input
                  type="checkbox"
                  onChange={this.props.updateCheckboxes}
                  name="county"
                  checked={this.props.checkboxes.county}
                />
                County
              </label>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default LocationSearchBar;
