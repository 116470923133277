import React, { Component } from "react";
import "./styles.scss";
import GovernmentLevel from "../GovernmentLevel/GovernmentLevel";
import LocationSearchBar from "../LocationSearchBar/LocationSearchBar";
import TemplateOptions from "../TemplateOptions/TemplateOptions";
import SelectedOfficial from "../SelectedOfficial/SelectedOfficial";

class SearchPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address: "",
      federal: null,
      state: null,
      local: null,
      county: null,
      activeCity: null,
      activeState: null,
      checkboxes: {
        state: true,
        federal: true,
        county: true,
        local: true,
      },
    };
  }

  updateCheckboxes = (e) => {
    const { name } = e.currentTarget;
    const checkbox = this.state.checkboxes[name];
    this.setState({
      checkboxes: { ...this.state.checkboxes, [name]: !checkbox },
    });
  };

  handleApiSearch = (data) => {
    this.resetSearchData();
    this.setState({ ...data });
  };

  resetSearchData = () => {
    this.setState(
      {
        federal: {},
        state: {},
        local: {},
        county: {},
        activeCity: null,
        activeState: null,
      },
      this.props.updateSelectedOffice(null)
    );
  };

  getGovtArray = () => {
    const govtArray = [];
    const { federal, state, local, county } = this.state;

    if (this.state.checkboxes.federal && federal && federal.reps) {
      govtArray.push(federal);
    }
    if (this.state.checkboxes.state && state && state.reps) {
      govtArray.push(state);
    }
    if (this.state.checkboxes.local && local && local.reps) {
      govtArray.push(local);
    }
    if (this.state.checkboxes.county && county && county.reps) {
      govtArray.push(county);
    }

    return govtArray;
  };

  renderGovernmentLevels() {
    let content;
    const govtArray = this.getGovtArray();

    if (this.props.selectedOffice) {
      content = (
        <SelectedOfficial
          {...this.props.selectedOffice}
          goBack={() => this.props.updateSelectedOffice(null)}
          city={this.state.activeCity}
          state={this.state.activeState}
        />
      );
    } else if (govtArray.length > 0) {
      content = govtArray.map((govt, idx) => {
        return (
          <GovernmentLevel
            {...govt}
            updateSelectedOffice={this.props.updateSelectedOffice}
          />
        );
      });
    }

    return content ? (
      <div className="govtContentContainer">{content}</div>
    ) : (
      content
    );
  }

  getSelectedOfficeProps() {
    const { selectedOffice } = this.props;

    if (!selectedOffice) {
      return {};
    }

    const {
      person,
      office,
      person: { emails },
    } = selectedOffice;

    const representative = {
      name: person.name,
      officeName: office.name,
    };

    if (emails && emails.length > 0) {
      Object.assign(representative, { email: emails[0] });
    }

    return representative;
  }

  clearAll = () => {
    this.setState({ address: "" }, this.resetSearchData);
  };

  renderClearButton() {
    if (this.props.selectedOffice || this.getGovtArray().length === 0) {
      return;
    }

    return (
      <button className="clearButton" onClick={this.clearAll}>
        Clear
      </button>
    );
  }

  updateAddress = (e) => {
    const { value } = e.currentTarget;
    this.setState({ address: value });
  };

  render() {
    return (
      <div className="app-container">
        <div className="header-and-search">
          <img
            className="imgheader"
            src={process.env.PUBLIC_URL + "/p4pheader.png"}
          />
          <LocationSearchBar
            address={this.state.address}
            updateAddress={this.updateAddress}
            handleApiSearch={this.handleApiSearch}
            checkboxes={this.state.checkboxes}
            updateCheckboxes={this.updateCheckboxes}
          />
        </div>
        <hr />
        {this.renderClearButton()}
        {this.renderGovernmentLevels()}
        <div className="template-options-container">
          <h2 className={"emailTemplatesTitle"}>Email Templates:</h2>
          <TemplateOptions
            {...this.getSelectedOfficeProps()}
            city={this.state.activeCity}
            state={this.state.activeState}
          />
        </div>
      </div>
    );
  }
}

export default SearchPage;
