import React, { useState } from "react";
import Modal from "react-modal";
import { MailOutline } from "@material-ui/icons";
import { BrowserView } from "react-device-detect";
import "./styles.scss";

const REPRESENTATIVE_NAME = "[REPRESENTATIVE_NAME]";
const CITY_STATE = "[CITY/STATE]";
const YOUR_NAME = "[YOUR_NAME]";

const ModalTemplate = ({
  isOpen,
  onRequestClose,
  subject,
  bodyText,
  email,
  name,
}) => {
  const [emailFormFields, updateEmailFormFields] = useState({
    email,
    subject,
    body: bodyText,
  });

  const updateEmailField = (e) => {
    const { name, value } = e.currentTarget;

    updateEmailFormFields((prevState) => ({ ...prevState, [name]: value }));
  };

  const standardMailUrl = `mailto:${
    emailFormFields.email
  }?subject=${encodeURIComponent(
    emailFormFields.subject
  )}&body=${encodeURIComponent(emailFormFields.body)}`;

  const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${
    emailFormFields.email
  }&su=${encodeURIComponent(emailFormFields.subject)}&body=${encodeURIComponent(
    emailFormFields.body
  )}`;

  const isNotValidEmail = (e) => {
    if (emailFormFields.body.includes(REPRESENTATIVE_NAME)) {
      return "Add the officials name at [POLITICIANS_NAME]";
    }

    if (emailFormFields.body.includes(CITY_STATE)) {
      return "include your city and state";
    }

    if (emailFormFields.body.includes(YOUR_NAME)) {
      return "replace [YOUR_NAME] with your own name";
    }

    if (emailFormFields.email.length === 0) {
      return "Please enter an email address";
    }

    if (emailFormFields.subject.length === 0) {
      return "Please enter a subject line";
    }

    return null;
  };

  const validateEmail = (e) => {
    const message = isNotValidEmail();
    if (message) {
      e.preventDefault();
      alert(message);
    }
  };

  return (
    <Modal isOpen={isOpen} className="modal">
      <div className="modalContainer">
        <button className="closeBtn" onClick={onRequestClose}>
          Close
        </button>
        <h2>{name}</h2>
        <div className="input-container">
          <label>Email Address:</label>
          <input
            onChange={updateEmailField}
            placeholder="Enter an email address"
            value={emailFormFields.email}
            name="email"
            className={"email-field"}
          />
        </div>
        <div className="input-container">
          <label>Subject:</label>
          <input
            onChange={updateEmailField}
            value={emailFormFields.subject}
            name="subject"
            className="subject-field"
          />
        </div>
        <div className="input-container">
          <label>
            Content:
            {emailFormFields.body.length === 0 && (
              <div className="error">Email content cannot be blank</div>
            )}
          </label>
          <textarea
            onChange={updateEmailField}
            value={emailFormFields.body}
            name="body"
            className="body-field"
          />
          <div className="emailsContainer">
            <a
              onClick={validateEmail}
              disabled={emailFormFields.body.length === 0}
              href={standardMailUrl}
              className="email"
            >
              <MailOutline className="iconImage" />
              Open In Email
            </a>
            <BrowserView>
              <a
                onClick={validateEmail}
                disabled={emailFormFields.body.length === 0}
                href={gmailUrl}
                target="_blank"
                className="gmailButton"
              >
                <img
                  className="iconImage"
                  src={process.env.PUBLIC_URL + "/gmail.png"}
                />
                Open In Gmail
              </a>
            </BrowserView>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ModalTemplate.defaultProps = {
  email: "",
};

export default ModalTemplate;
