const federal_pattern = "ocd-division/country:us";
const state_pattern = /ocd-division\/country:us\/state:(\D{2}$)/;
const cd_pattern = /ocd-division\/country:us\/state:(\D{2})\/cd:/;
const sl_pattern = /ocd-division\/country:us\/state:(\D{2})\/(sldl:|sldu:)/;
const county_pattern = /ocd-division\/country:us\/state:\D{2}\/county:\D+/;
const local_pattern = /ocd-division\/country:us\/state:\D{2}\/place:\D+/;
const district_pattern = /ocd-division\/country:us\/district:\D+/;

const federal_offices = [
  "United States Senate",
  "United States House of Representatives",
  "U.S. Senator",
  "U.S. Representative",
];

const social_icon_lookup = {
  YouTube: "youtube",
  Facebook: "facebook",
  Twitter: "twitter",
  GooglePlus: "google-plus",
};

const social_link_lookup = {
  YouTube: "https://www.youtube.com/user/",
  Facebook: "https://www.facebook.com/",
  Twitter: "https://twitter.com/",
  GooglePlus: "https://plus.google.com/",
};

const checkFederal = (divisionId, officeName) => {
  if (
    divisionId == federal_pattern ||
    cd_pattern.test(divisionId) ||
    federal_offices.indexOf(officeName) >= 0
  ) {
    return true;
  }

  return false;
};

const checkState = (divisionId) => {
  if (state_pattern.test(divisionId) || sl_pattern.test(divisionId)) {
    return true;
  }

  return false;
};

const checkCounty = (divisionId) => {
  if (county_pattern.test(divisionId)) {
    return true;
  }

  return false;
};

const checkLocal = (divisionId) => {
  if (local_pattern.test(divisionId)) {
    return true;
  }

  return false;
};

const parseApiResults = (data) => {
  let props = {
    federal: {
      name: "United States",
      reps: [],
    },
    state: {
      name: "",
      reps: [],
    },
    county: {
      name: "",
      reps: [],
    },
    local: {
      name: "",
      reps: [],
    },
  };
  const { divisions, officials, offices } = data;

  let division, office, person, divisionId;

  Object.keys(divisions).forEach((dKey, idx) => {
    division = divisions[dKey];
    if (division.officeIndices === undefined) {
      return false;
    }

    return division.officeIndices.forEach((off, idx) => {
      office = offices[off];
      const { divisionId } = office;

      return offices[off].officialIndices.forEach((official, i) => {
        person = officials[official];
        const info = {
          person,
          office,
          divisionId,
        };

        if (checkFederal(info.divisionId, info.office.name)) {
          props.federal = {
            ...props.federal,
            reps: [...props.federal.reps, info],
          };
        } else if (checkState(info.divisionId)) {
          props.state = {
            ...props.state,
            name: division.name,
            reps: [...props.state.reps, info],
          };
        } else if (checkCounty(info.divisionId)) {
          props.county = {
            ...props.county,
            name: division.name,
            reps: [...props.county.reps, info],
          };
        } else if (checkLocal(info.divisionId)) {
          props.local = {
            ...props.county,
            name: division.name,
            reps: [...props.county.reps, info],
          };
        }
      });
    });
  });

  return props;
};

export default parseApiResults;
