import React from "react";
import { ArrowBack } from "@material-ui/icons";
import Rep from "../Rep/Rep";
import "./styles.scss";

const SelectedOffical = ({ goBack, office, person }) => {
  return (
    <div>
      <ArrowBack onClick={goBack} className="arrowBack" />
      <Rep large office={office} person={person} />
    </div>
  );
};

export default SelectedOffical;
