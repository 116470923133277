import React, { useState } from "react";
import "./styles.scss";

const Rep = ({ person, office, updateSelectedOffice, large }) => {
  const [showPhoto, toggleShowPhoto] = useState(true);
  const handleOnClick = (e) => {
    e.preventDefault();
    if (large) {
      return;
    }

    const payload = {
      person,
      office,
    };
    updateSelectedOffice(payload);
  };

  return (
    <div
      className={`repContainer ${large ? "large" : ""}`}
      onClick={handleOnClick}
    >
      {person.photoUrl && showPhoto && (
        <img
          src={person.photoUrl}
          className="photoUrl"
          onError={() => toggleShowPhoto(false)}
        />
      )}
      <div className="repData">
        <div className="name">{person.name}</div>
        <div className="office">{office.name}</div>
        {person.emails ? (
          <div className="repEmail">
            <div>
              Email: <span className="personEmail">{person.emails[0]}</span>
            </div>
          </div>
        ) : (
          <div className="no-email">No email on file</div>
        )}
      </div>
    </div>
  );
};

export default Rep;
