import React from "react";
import Reps from "../Reps/Reps";
import "./GovernmentLevel.scss";
const GovernmentLevel = ({ name, reps, updateSelectedOffice }) => {
  return (
    <>
      <br />
      <div className="governmentLevel">
        <h1 className="govt-level-title">{name}</h1>
        <Reps reps={reps} updateSelectedOffice={updateSelectedOffice} />
      </div>
    </>
  );
};

GovernmentLevel.defaultProps = {
  reps: [],
};

export default GovernmentLevel;
