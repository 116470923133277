import React, { useState, useEffect } from "react";
import "./styles.scss";

const REPRESENTATIVE_NAME = "[REPRESENTATIVE_NAME]";
const CITY_STATE = "[CITY/STATE]";

const TemplateCard = ({
  name,
  body,
  handleClick,
  personName,
  officeName,
  subject,
  city,
  state,
  email,
  idx,
}) => {
  const [bodyText, updateBodyText] = useState(body);

  const handleTemplateClick = (e) => {
    e.preventDefault();

    const payload = { bodyText, subject, name, email };
    handleClick(payload);
  };

  useEffect(() => {
    let text = body;
    if (personName === null && city === null && state === null) {
      updateBodyText(body);
      return;
    }

    if (personName) {
      text = text.replace(REPRESENTATIVE_NAME, personName);
    }

    if (city && state) {
      text = text.replace(CITY_STATE, `${city}, ${state}`);
    } else if (state) {
      text = text.replace(CITY_STATE, state);
    }

    updateBodyText(text);
  }, [personName, officeName, city, state]);

  const bodyDivs = bodyText.split("\n\n").map((text) => {
    return <div className="body-text-section">{text}</div>;
  });

  return (
    <a
      className={"templateCardContainer"}
      href={"#"}
      onClick={handleTemplateClick}
    >
      <div className={"innerContainer"}>
        <div className={"name"}>{name}</div>
        <div className={"body"}>{bodyDivs}</div>
      </div>
    </a>
  );
};

TemplateCard.defaultProps = {
  personName: null,
  officeName: null,
  city: null,
  state: null,
};

export default TemplateCard;
