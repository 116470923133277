import React from "react";
import Rep from "../Rep/Rep";

const Reps = (props) => {
  return props.reps.map((rep, i) => {
    return <Rep {...rep} updateSelectedOffice={props.updateSelectedOffice} />;
  });
};

export default Reps;
