const templates = [
  {
    name: "COVID-19 Relief",
    subject: "We the People Demand Our Stimulus Checks",
    body:
      "Dear [REPRESENTATIVE_NAME],\n\nI am a resident of [CITY/STATE] and I am getting in touch to demand a fair COVID-19 stimulus relief package.\n\n After Congress and the Senate recently passed the COVID-19 Economic Relief Bill, signed off by President Trump, that issued struggling Americans a one-off payment of $600, I would like to remind you of a few facts:\n\n • $1,152 - The average cost of health insurance for a family per month in the US.\n\n • $1,909 - The national average cost of rent for a 2BR apartment.\n\n• 10.7 million - The number of unemployed Americans (4.9 million higher than in February.)\n\n• 19.6M - The number of COVID-19 cases in the US.\n\n• 339,000 (and counting) - The number of COVID-19 related deaths in the US.\n\nThis is public information, and you are a public servant. However, you continue to ignore the plight of the average US Citizen by issuing a “relief” stimulus package akin to crumbs for the dogs, while trying to force a narrative that we should be thankful for gross negligence towards public welfare.\n\nAs a voting member of your constituency, I urge you to fight for mine and my fellow Americans' right to survive amid one of the biggest global crises in world history.\n\nIn the coming days, not months, I demand that Congress & the Senate use every existing political avenue to increase the average direct deposit to at minimum $2000, and mandate that it be recurring until there are established safeguards against the spread of the coronavirus and the rapid loss of lives.\n\nThank you for your attention to my concerns. I hope to hear back from you soon.\n\nSigned,\n\n[YOUR_NAME]",
  },
  {
    name: "Qualified Immunity",
    subject: "End Qualified Immunity",
    body:
      "Dear [REPRESENTATIVE_NAME],\n\n As a voting member of your constituency and someone who cares about Police Reform, I am writing to urge you to propose a Bill to end Qualified Immunity for Police Officers.\n\nToo many innocent civilians have been murdered at the hands of negligent police officers, and we demand that they be held accountable.\n\nThis is a human rights issue, and I will not support a candidate who refuses to support my human rights as an American Citizen.\n\nThank you for your attention to my concerns. I hope to hear back from you soon.\n\nSigned,\n\n[YOUR_NAME]",
  },
  {
    name: "Justice For Breonna Taylor",
    subject: "Justice For Breonna Taylor",
    body:
      "Dear [REPRESENTATIVE_NAME],\n\nMy name is [YOUR_NAME] and I am a resident of [CITY/STATE]. I am emailing today to demand accountability in the unjust murder of Breonna Taylor. I demand that Jonathan Mattingly, Brett Hankison and Myles Cosgrove each be arrested and criminally charged for the wrongful death of Breonna Taylor.\n\nThese policemen recklessly executed a raid using a “no knock” warrant on the wrong home, concluding in the death of an innocent American citizen.\n\nDespite your almost 3 months of inaction, Breonna’s life matters and I want justice for her. I will not support a candidate who refuses to support the pursuit of justice.\n\nSigned,\n\n[YOUR_NAME]",
  },
  {
    name: "Prison Reform 1 (Ban Private Prisons)",
    subject: "Prison Reform is Needed",
    body:
      "Dear [REPRESENTATIVE_NAME],\n\n As a voting member of your constituency and someone who cares about Prison Reform, I am writing to urge you to propose a bill to end the legality of For-Profit Private Prisons. \n\nThe current Prison-Industrial Complex is an embodiment of a racist institution, deeply rooted in our history of slavery in this country. I demand comprehensive reform on this matter, which includes but is not limited to a ban of private prisons.\n\nThis is a human rights issue, and I will not support a candidate who refuses to support my human rights as an American Citizen.\n\nThank you for your attention to my concerns. I hope to hear back from you soon.\n\nSigned,\n\n[YOUR_NAME]",
  },
  {
    name: "Prison Reform 2 (Non-violent crimes)",
    subject: "Prison Reform is Needed",
    body:
      "Dear [REPRESENTATIVE_NAME],\n\nMy name is [YOUR_NAME] and I am a resident of [CITY/STATE]. I am emailing today to demand you to fight for the release/pardoning of convicted individuals for non-violent drug crimes.\n\nFamilies, especially black families, are being torn apart due to antiquated racist policies that disproportionately target and punish them.\n\nInstead, we want you to invest more time and money into the improvement of social institutions e.g. After school programs, drug rehabilitation centers, and mental health counseling, in order to deal with the root causes of these issues.\n\nI demand a fair America for all, regardless of race, religion, ethnicity or sexual orientation.\n\nSigned,\n\n[YOUR_NAME]",
  },
  {
    name: "Education Reform",
    subject: "Yes to Education Reform",
    body:
      `Dear [REPRESENTATIVE_NAME],\n \nAs a voting member of your constituency and someone who cares about Education Reform, I am writing to let you know that I will only support a candidate who commits to significantly increasing the Public Education budget every year for their full terms.\n\nWe cannot be a community that commits more funding to our Police Departments than to our Children’s futures. I demand that you take the necessary steps to make sure our children have access to the best teachers and facilities possible, in order to set them up for success in the future.\r\rI demand that you introduce legislation to:\n\nIncrease funding - For the fair financial compensation of Public School Teachers.\n\nIncrease funding - For the restoration of libraries in the Inner City/Poorer communities.\n\nIncrease funding - For student access to free online courses.\n\nIncrease funding - For student access to free After-School Programs.\n\nThank you for your attention to my concerns. I hope to hear back from you soon.\n\nSigned,\n\n[YOUR_NAME]`,
  },
];

export { templates };
