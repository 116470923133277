import React, { Component } from "react";
import "./App.css";
import SearchPage from "../SearchPage/SearchPage";
import history from "../../utils/history";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOffice: null,
    };
  }

  updateSelectedOffice = (selectedOffice) => {
    this.setState({ selectedOffice });
  };

  navigateToTemplate = () => {
    let email = "";
    let { name, emails } = this.state.selectedOffice;
    name = encodeURIComponent(name);
    if (emails.length > 0) {
      email = encodeURIComponent(emails[0]);
    }

    history.push(`/template/${name}/${email}`);
  };

  render() {
    return (
      <SearchPage
        city={this.state.activeCity}
        state={this.state.activeState}
        updateSelectedOffice={this.updateSelectedOffice}
        selectedOffice={this.state.selectedOffice}
      />
    );
  }
}

export default App;
