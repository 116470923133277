import React, { useState } from "react";
import TemplateCard from "../TemplateCard/TemplateCard";
import { templates } from "../../emailTemplates";
import ModalTemplate from "../ModalTemplate/ModalTemplate";
import styles from "./styles.scss";

const TemplateOptions = ({ name, officeName, city, state, email }) => {
  const [activeTemplate, updateActiveTemplate] = useState(null);

  const closeModal = () => {
    updateActiveTemplate(null);
  };

  const handleClick = (payload) => {
    updateActiveTemplate(payload);
  };

  return (
    <>
      {activeTemplate !== null && (
        <ModalTemplate
          {...activeTemplate}
          isOpen={activeTemplate !== null}
          onRequestClose={closeModal}
        />
      )}
      <div className={"templateContainer"}>
        {templates.map((template, idx) => {
          return (
            <TemplateCard
              {...template}
              idx={idx}
              handleClick={handleClick}
              personName={name}
              officeName={officeName}
              city={city}
              state={state}
              email={email}
            />
          );
        })}
      </div>
    </>
  );
};

TemplateOptions.defaultProps = {
  email: "",
};

export default TemplateOptions;
